import React from 'react'
import { Link } from 'react-router-dom'
// import { ArticleMeta } from 'ion-article-cmp'
import Ad from '../../components/Ad'
import FORMATS from '../../components/AdFormats'
import { ImageOrNoImage } from 'ion-image'
import ClockImage from '../../static/icons/time.svg'
// import theStarButton from '../../static/general/the-star.png'
import WhatsForDinnerButton from '../../static/whatsfordinner.png'
import IOLButton from '../../static/iol.jpg'
import SACovidButton from '../../static/general/SAcoronavirus-button.jpg'
import { Mobile, MobileElse } from 'ion-media'

const MainArticle = ({ article, noImage }) => {
  console.log('Article: ', article)
  return (
    <article className='highlight-main-article'>
      <div className='article-thumb'>
        <Link to={'/' + article.section} className={'section ' + article.section.split('/')[0]}>{article.sectionLabel}</Link>
        <Link to={'/' + article.getCanonicalUri()}>
          <Mobile>
            <ImageOrNoImage image={article.image} width={310} alt={article.headline} shape='16x9' noImage={noImage} />
          </Mobile>
          <MobileElse>
            <ImageOrNoImage image={article.image} width={650} alt={article.headline} shape='16x9' noImage={noImage} />
          </MobileElse>
        </Link>
      </div>
      <Link to={'/' + article.getCanonicalUri()}>
        <h1>{article.headline}</h1>
        <p className='highlight-meta'>by {article.author} <img src={ClockImage} alt='Time of article published' />{article.formatAgo()}</p>
        <p>{article.getAbstract()}</p>
      </Link>
    </article>
  )
}

const ArticleCard = ({ article, noImage }) => (
  <article>
    <div className='article-thumb'>
      <Link to={'/' + article.section} className={'section ' + article.section.split('/')[0]}>{article.sectionLabel}</Link>
      <Link to={'/' + article.getCanonicalUri()}>
        <ImageOrNoImage image={article.image} width={310} alt={article.headline} shape='16x9' noImage={noImage} />
      </Link>
    </div>
    <Link to={'/' + article.getCanonicalUri()}>
      <h5>{article.headline}</h5>
      <p className='highlight-meta'><img src={ClockImage} alt='Time of article published' />{article.formatAgo()}</p>
    </Link>
  </article>
)

const Default = ({ articles, section, noImage, isConnected }) => (
  <>
    <div className='highlighted-articles row'>
      <MainArticle article={articles[0]} noImage={noImage} />
      <aside>
        <div className='advert'>
          <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='mpu-topright' targeting={{ mpu: 'topright' }} collapseEmptyDiv className='ad-mpudynamic-topright' {...FORMATS.mpudynamic} />
        </div>
      </aside>
    </div>
    <div className='highlight-buttons row'>
      <a href='https://sacoronavirus.co.za/' target='_blank' rel='noopener noreferrer nofollow'><img src={SACovidButton} title='COVID-19 Resource Portal' alt='COVID-19 Resource Portal' /></a>
      <Link to='/impilo-yabantu/whatsfordinner'><img src={WhatsForDinnerButton} alt='WhatsForDinner' /></Link>
      <a href='https://www.iol.co.za' target='_blank' rel='noopener noreferrer nofollow'><img src={IOLButton} alt='IOL' /></a>
    </div>
    <>
      {[...Array(Math.ceil((articles.length - 1) / 3)).keys()].map((row, index) => (
        <div key={'row-' + index} className='highlight-row row'>
          {articles.slice((row * 3) + 1, row * 3 + 4).map((article, index) => (
            <ArticleCard key={article.contentKey} article={article} noImage={noImage} />
          ))}
        </div>
      ))}
    </>
  </>
)

export default Default
